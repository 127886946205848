/* istanbul ignore file */
import axios from '@fs/zion-axios'
import SERVICE from './serviceName'

// DOCS: https://www.familysearch.org/service/memories/memmrus/

const BASE = `${SERVICE}/memmrus/uploads`

/**
 * Create a new upload resource location to manage a file upload.
 * Use the location in the response header to complete the upload.
 */
export const startUpload = ({ uploadLength, uploadMetadata }) => {
  const config = {
    headers: {
      UPLOAD_LENGTH: uploadLength,
      UPLOAD_METADATA: uploadMetadata,
    },
  }
  return axios.post(`${BASE}`, null, config).then((response) => {
    // The location header is the uploadId
    const uploadId = response.data.header.Location
    return uploadId
  })
}

/**
 * Delete a completed or partial upload.
 * This is supplied if the creator of an upload wants to clean up before submitting the uploadId to another application to consume.
 * The upload has a TTL and be cleaned up when the TTL expires.
 * If you use the delete, but have submitted the uploadId to anther application to consume, you may remove the upload before the application has the chance to consume it.
 */
export const deleteUpload = ({ uploadId }) => {
  return axios.delete(`${BASE}/${uploadId}`)
}

/**
 * Get the file details
 */
export const getUpload = ({ uploadId }) => {
  return axios.get(`${BASE}/${uploadId}`)
}

/**
 * Get current upload status of the file.
 * The path for this call is the url in the location header from a POST or a previous HEAD request.
 * The offset from the head will NOT be cached or stored
 */
export const getUploadStatus = ({ uploadId }) => {
  return axios.head(`${BASE}/${uploadId}`)
}

/**
 * Upload bytes of the file.
 * May be the entire file, or only a part.
 * Note: Not all infrastructures supports PATCH.
 * If you are unable to use PATCH.
 * Use POST or PUT with the X-HTTP-Method-Override header set to PATCH.
 * This will invoke the PATCH using a POST or PUT verb supported by the infrastructure
 */
export const uploadPart = ({ uploadId }) => {
  return axios.patch(`${BASE}/${uploadId}`)
}
