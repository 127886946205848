import axios from '@fs/zion-axios'
import { i18n } from '@fs/zion-locale'
import SERVICE from './serviceName'
import { eventConfig } from '../../config'

// DOCS: https://fhconfluence.churchofjesuschrist.org/x/6glUEg

const BASE = `${SERVICE}/schedules/${eventConfig.conference}`
const lang = i18n.language
const config = {
  headers: { 'Accept-Language': lang, 'Content-Language': lang, 'Content-Type': 'application/json' },
  data: {},
}

// Get User Schedule for Event
export const getUserSchedule = async () => {
  return axios.get(`${BASE}`, config)
}

/**
 * Get Daily Schedule for Event
 * @param {String} date The day of the event, pattern: \d{4}-\d{2}-\d{2}, example: 2023-01-31
 * @returns {Promise} A Promise resolving to the event's day schedule information
 */
export const getDaySchedule = async ({ date }) => {
  return axios.get(`${BASE}/${date}`, config)
}

/**
 * Put Preferences for Time Slot
 * @param {String} date The day of the event, pattern: \d{4}-\d{2}-\d{2}, example: 2023-01-31
 * @param {String} time The time slot to be updated, pattern: \d{2}:\d{2}, example: 14:00
 * @param {Object} body The user's preferences for the given time slot, example: {"preferred": "session-1", "alternates":["session-2"]}
 * @returns {Promise} A Promise resolving to the event's day schedule time slot information
 */
export const updateTimeSlot = async ({ date, time }, body) => {
  return axios.put(`${BASE}/${date}/${time}`, body, config)
}

/**
 * Update User Schedule for Event.
 * This service is only used to consolidate the Calendar data with
 * the classes that were added to mySchedule
 *
 * @param {Object} body The user's schedule
 * @returns {Promise} A Promise resolving to the user's mySchedule
 */
export const updateSchedule = async (body) => {
  return axios.put(`${BASE}`, body, config)
}

/**
 * Delete Preferences for Time Slot
 * @param {String} date The day of the event, pattern: \d{4}-\d{2}-\d{2}, example: 2023-01-31
 * @param {String} time The time slot to be updated, pattern: \d{2}:\d{2}, example: 14:00
 * @returns {Promise} A Promise resolving to the event's day schedule time slot information
 */
export const deleteTimeSlot = async ({ date, time }) => {
  return axios.delete(`${BASE}/${date}/${time}`, config)
}

// Get the list of locales from mySchedule
export const getScheduleLocales = async () => {
  return axios.get(`${BASE}/locales`, config)
}
