import React from 'react'
import { useTranslation } from 'react-i18next'
import { H3, LayoutBand, Separator, Subheading, Row, Image } from '@fs/zion-ui'
import { blackWavesJpg, errorMessageBotSvg } from '../../images'

const ErrorFallback = () => {
  const [t] = useTranslation()

  return (
    <LayoutBand image={blackWavesJpg} style={{ backgroundPosition: 'center', backgroundSize: 'cover' }} marginY="lg">
      <Row alignX="center">
        <Image src={errorMessageBotSvg} alt="" />
      </Row>
      <Separator size="xl" />
      <H3 centered>
        {t('video.error.heading', 'It looks like something went wrong')}
        <Separator size="xs" />
        <Subheading>
          {t(
            'generic.api.error.message',
            'We’re sorry, but there’s an issue loading the content. Please try again shortly while we investigate.'
          )}
        </Subheading>
      </H3>
    </LayoutBand>
  )
}

export default ErrorFallback
