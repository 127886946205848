import React from 'react'
import { Separator, Row, useContainerWidth, useStatusOverlay } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { ContentAdd, ContentCheck, MediaPlay } from '@fs/zion-icon'
import { useUser } from '@fs/zion-user'
import { trackLink } from '@fs/zion-analytics'
import CardListItem from '../CardListItem'
import Timestamp from '../../Timestamp'
import { getRelativePath, getCustomImage } from '../../helpers'
import { PillButton, SocialShareButton } from '../../buttons'
import { InPersonBadge, InPersonOnlyBadge, LiveBadge, OnlineBadge, SessionDifficultyBadge } from '../../Badge'
import { useFlagsContext, useLoggedOutOverlay, useTimeTravel } from '../../../providers'
import { useScheduleContext } from '../../../components/schedule/ScheduleProvider'
import {
  MY_SCHEDULE_TAB,
  mapToMyScheduleDate,
  mapToMyScheduleHour,
} from '../../../components/schedule/helpers/scheduleHelpers'
import Classroom from '../../Classroom'
import { FLAGS } from '../../../config'

const Badges = ({ location, difficulty, isLive }) => {
  return (
    <Row gutters="nano">
      {isLive && <LiveBadge />}
      {location?.includes('Online') && <OnlineBadge />}
      {location?.includes('In Person') && <InPersonBadge />}
      {difficulty && <SessionDifficultyBadge sessionDifficulty={difficulty} />}
    </Row>
  )
}

const Actions = ({ data, calendarDate, linkPage, asIconButton, showWatchButton }) => {
  const [t] = useTranslation()
  const { contentId, title, url, description, inMySchedule } = data
  const thumbnail = getCustomImage(data)
  const user = useUser()
  const { changeTab, addToMySchedule, removeFromMySchedule, day } = useScheduleContext()
  const showStatusOverlay = useStatusOverlay()
  const { overlay: loggedOutOverlay } = useLoggedOutOverlay()
  const showAddedOverlay = () => {
    showStatusOverlay({
      message: t('schedule.added.my-schedule.alert', 'Added to My Schedule'),
      type: 'success',
      actionLabel: t('schedule.view.my-schedule.alert', 'View Now'),
      actionCallback: () => changeTab(MY_SCHEDULE_TAB),
      transient: true,
    })
  }
  const showErrorOverlay = () => {
    showStatusOverlay({
      message: t(
        'schedule.error.my-schedule.alert',
        'You may choose up to four sessions for each time slot. Please select your top four sessions.'
      ),
      type: 'error',
      dismissable: true,
    })
  }

  const onClick = (e) => {
    const loggedIn = user?.signedIn ?? false
    if (!loggedIn) {
      trackLink({ name: 'MySchedule: logged out', action: 'click_action' })
      loggedOutOverlay?.handleClick?.(e)
    } else if (day) {
      const sessionDate = new Date(calendarDate)
      const date = mapToMyScheduleDate(sessionDate)
      const time = mapToMyScheduleHour(sessionDate)
      const value = {
        calendarDate,
        contentId,
        date,
        time,
      }
      if (!inMySchedule) {
        trackLink({ name: `Add to MySchedule: ${title}`, action: 'click_action' })

        addToMySchedule(value).then(showAddedOverlay).catch(showErrorOverlay)
      } else {
        trackLink({ name: `Remove from MySchedule: ${title}`, action: 'click_action' })
        removeFromMySchedule(value)
      }
    }
  }

  return (
    <Row alignY="middle">
      <Row growX>
        {showWatchButton && (
          <PillButton Icon={MediaPlay} emphasis="medium" to={url}>
            {t('schedule.watch.label', 'Watch')}
          </PillButton>
        )}
        <PillButton
          Icon={inMySchedule ? ContentCheck : ContentAdd}
          emphasis={inMySchedule ? 'high' : 'medium'}
          onClick={onClick}
          color={inMySchedule ? 'blue' : undefined}
        >
          {t('schedule.schedule.label', 'Schedule')}
        </PillButton>
      </Row>
      <SocialShareButton
        title={title}
        linkPage={linkPage}
        asIconButton={asIconButton}
        url={url}
        thumbnail={thumbnail}
        description={description}
        size={asIconButton ? 'sm' : 'lg'}
      />
    </Row>
  )
}

export default function ScheduleCard({ data, calendarDate, linkPage, dataForVideo, isLive, showWatchButton }) {
  const atWidth = useContainerWidth()
  const { [FLAGS.SITE_EXPERIENCE]: siteExperienceFlag } = useFlagsContext()
  const { treatment: siteTreatment } = siteExperienceFlag

  const {
    classroomName,
    conferenceYear,
    date,
    title,
    description,
    creators,
    exhibitors,
    url,
    promo,
    sessionLocation,
    sessionDifficulty,
  } = data

  const to = getRelativePath(url)
  const asIconButton = atWidth({ default: true, xl: false })
  const linkName = `Watch: ${title}: Title`
  const { getNow } = useTimeTravel()
  const currentDate = new Date(getNow())

  const creatorsWithoutLinks = creators?.map((creator) => ({ ...creator, url: undefined }))
  const exhibitorsWithoutLinks = exhibitors?.map((exhibitor) => ({ ...exhibitor, url: undefined }))

  const isInPersonOnly = sessionLocation === 'In Person'

  return (
    <CardListItem
      expandable={['registration', 'countdown'].includes(siteTreatment)}
      badges={
        <Badges location={sessionLocation || promo?.sessionLocation} difficulty={sessionDifficulty} isLive={isLive} />
      }
      timestamp={<Timestamp conferenceYear={conferenceYear} timestamp={calendarDate ?? date} />}
      classroom={
        classroomName &&
        sessionLocation?.includes('In Person') &&
        currentDate <= date && <Classroom classroom={`- ${classroomName}`} />
      }
      heading={title}
      linkName={linkName}
      description={description}
      creators={siteTreatment === 'registration' ? creatorsWithoutLinks : creators}
      exhibitors={siteTreatment === 'registration' ? exhibitorsWithoutLinks : exhibitors}
      to={siteTreatment === 'registration' ? '' : to}
      dataForVideo={dataForVideo}
      actions={
        siteTreatment === 'registration' ? null : (
          <Actions
            to={to}
            data={data}
            calendarDate={calendarDate}
            linkPage={linkPage}
            asIconButton={asIconButton}
            showWatchButton={showWatchButton}
          />
        )
      }
      additionalContent={
        siteTreatment === 'countdown' && isInPersonOnly ? (
          <>
            <Separator size="xxs" />
            <InPersonOnlyBadge />
            <Separator size="xxs" />
          </>
        ) : null
      }
    />
  )
}
