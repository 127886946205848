import React from 'react'
import {
  FlowGrid,
  Image,
  MoreLess,
  Row,
  Separator,
  WhiteTheme,
  useContainerWidth,
  H1,
  Overline,
  Subheading,
} from '@fs/zion-ui'
import ErrorBoundary from '@fs/zion-error-boundary'
import { css } from '@emotion/core'
import { useIsMdWidth } from '../../../hooks'
import ResponsiveSeparator from '../../ResponsiveSeparator'
import {
  getCustomImage,
  getRelativePath,
  isConferencePromo,
  isCreatorConferencePromo,
  isImageOptionDetail,
  isListModule,
  isVideoLeadDetail,
} from '../../helpers'
import { PillButton } from '../../buttons'
import SafeRichText from '../../SafeRichText'
import RainbowImageBand from '../../RainbowImageBand'
import RainbowRibbonBand from '../../RainbowRibbonBand'
import { VideoProvider } from '../../video'
import PageHeading from '../PageHeading'
import ResponsiveSectionBand from '../../ResponsiveSectionBand'
import RainbowText from '../../RainbowText'
import CarouselList from '../../CarouselList'
import ResponsiveLayoutBand from '../../ResponsiveLayoutBand'
import ErrorFallback from '../../ErrorFallback'

const speakerImgCss = css`
  --size: 33vw;
  --max-size: 350px;
  border-radius: 50%;
  grid-area: img;
  height: var(--size);
  max-height: var(--max-size);
  max-width: var(--max-size);
  object-fit: cover;
  width: var(--size);
  background: #fff9;

  [is-md-width] & {
    --size: 50vw;
  }
`

const LeadCreatorConferencePromo = ({ promo }) => {
  const atWidth = useContainerWidth()
  return (
    <ResponsiveSectionBand>
      <Row
        data-testid="speaker-info"
        alignY="middle"
        alignX={atWidth({ default: 'center', sm: 'start' })}
        wrap={atWidth({ default: true, sm: false })}
      >
        <img css={speakerImgCss} src={getCustomImage(promo)} alt="" aria-hidden noShrinkX alignY="top" loading="lazy" />
        <div>
          <strong>
            <H1 centered={atWidth({ default: true, sm: false })} data-testid="speaker-name">
              {promo.title}
            </H1>
          </strong>
          <ResponsiveSeparator />
          <PillButton to={getRelativePath(promo.url)} linkName={promo.ctaText}>
            {promo.ctaText}
          </PillButton>
        </div>
      </Row>
      <ResponsiveSeparator />
      <MoreLess data-testid="speaker-bio" collapsedLineCount={3}>
        <SafeRichText dirtyInput={promo.description} />
      </MoreLess>
    </ResponsiveSectionBand>
  )
}

const ConferencePromoLead = ({ data, flavor, children }) => {
  const isMdWidth = useIsMdWidth()
  const promo = data.lead.find(isConferencePromo)
  const noImage = !promo?.image
  if (promo && noImage) return <BasicPageLead data={promo} flavor={flavor} />
  return promo ? (
    <>
      {isCreatorConferencePromo(promo) ? (
        <LeadCreatorConferencePromo promo={promo}>{children}</LeadCreatorConferencePromo>
      ) : (
        <RainbowImageBand
          as="section"
          backgroundImage={getCustomImage(promo)}
          minHeight={isMdWidth ? 400 : 580}
          alignY="bottom"
          flavor="smoke"
          marginX="md"
          marginY="lg"
          noScrim
        >
          <WhiteTheme>
            <strong>
              <H1 centered>
                <Overline>{promo.overlineText}</Overline>
                {promo.title}
                <Separator />
                {!isMdWidth && (
                  <Subheading as="div">
                    <Row alignX="center">
                      <SafeRichText dirtyInput={promo.description} />
                    </Row>
                  </Subheading>
                )}
              </H1>
            </strong>
          </WhiteTheme>
          <Separator size="sm" />
          {children}
          {promo.url && promo.ctaText && (
            <Row alignX="center" wrap>
              <PillButton to={getRelativePath(promo.url)} linkName={promo.ctaText}>
                {promo.ctaText}
              </PillButton>
            </Row>
          )}
        </RainbowImageBand>
      )}
    </>
  ) : null
}

const ImageVideoLeadHeading = ({ data, flavor }) => {
  const atWidth = useContainerWidth()
  return (
    <div>
      <H1 centered={atWidth({ default: true, xl: false })}>
        <RainbowText flavor={flavor}>{data.name}</RainbowText>
      </H1>
      {data.description && (
        <>
          <Separator />
          <Row alignX={atWidth({ default: 'center', xl: 'start' })}>
            <SafeRichText dirtyInput={data.description} />
          </Row>
        </>
      )}
    </div>
  )
}

const VideoDetailLead = ({ data, flavor, children }) => {
  const atWidth = useContainerWidth()
  const videoLead = data.lead.find(isVideoLeadDetail)
  const videoDetail = videoLead?.videoDetail

  return videoLead ? (
    <>
      <RainbowRibbonBand flavor={flavor} />
      {children}
      <ResponsiveSectionBand maxWidth="xl">
        <FlowGrid columnCount={atWidth({ default: 1, xl: 2 })}>
          <ImageVideoLeadHeading flavor={flavor} data={data} />
          <div style={{ aspectRatio: '16/9', flexGrow: 'unset' }}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <VideoProvider
                videoProvider={videoDetail.videoProvider}
                thumbnail={getCustomImage(videoDetail.thumbnail)}
                contentLocale={videoDetail.contentLocale}
              />
            </ErrorBoundary>
          </div>
        </FlowGrid>
        <ResponsiveSeparator />
      </ResponsiveSectionBand>
    </>
  ) : null
}

const ImageOptionLead = ({ data, flavor, children }) => {
  const atWidth = useContainerWidth()
  const imageLead = data.lead.find(isImageOptionDetail)
  const image = imageLead?.imageDetail?.custom?.image
  return imageLead ? (
    <>
      <RainbowRibbonBand flavor={flavor} />
      {children}
      <ResponsiveSectionBand maxWidth="xl">
        <FlowGrid columnCount={atWidth({ default: 1, xl: 2 })}>
          <ImageVideoLeadHeading data={data} flavor={flavor} />
          <Image style={{ aspectRatio: '16/9', flexGrow: 'unset' }} alt="" src={image.src} loading="lazy" />
        </FlowGrid>
      </ResponsiveSectionBand>
      <ResponsiveSeparator />
    </>
  ) : null
}

const CarouselLead = ({ data, flavor }) => {
  const carouselLead = data.lead.find(isListModule)

  if (!carouselLead) {
    return null
  }

  return (
    <>
      <RainbowRibbonBand flavor={flavor} />
      <ResponsiveLayoutBand>
        <PageHeading
          {...data}
          overline={data?.overlineText}
          heading={data?.name || data?.title}
          subHeading={data?.subHeading}
          description={data?.description}
          flavor={flavor}
          bold
          centered
          fullWidth
        />
      </ResponsiveLayoutBand>
      <ResponsiveSectionBand maxWidth="xl">
        <CarouselList items={carouselLead.items} flavor={flavor} />
      </ResponsiveSectionBand>
    </>
  )
}

export function BasicPageLead({ data, flavor, children }) {
  return (
    <>
      <RainbowRibbonBand flavor={flavor} />
      {children}
      <ResponsiveSeparator />
      <ResponsiveSectionBand maxWidth="xxl">
        <PageHeading
          {...data}
          overline={data?.overlineText}
          heading={data?.name || data?.title}
          subHeading={data?.subHeading}
          description={data?.description}
          flavor={flavor}
          bold
          centered
          fullWidth
        />
      </ResponsiveSectionBand>
    </>
  )
}

export function PageLead({ data, ...props }) {
  const lead = data?.lead || []
  return (
    <>
      {lead.length > 0 ? (
        <>
          <VideoDetailLead data={data} {...props} />
          <ConferencePromoLead data={data} {...props} />
          <ImageOptionLead data={data} {...props} />
          <CarouselLead data={data} {...props} />
        </>
      ) : (
        <BasicPageLead data={data} {...props} />
      )}
    </>
  )
}
