import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from '@fs/zion-user'
import { ListItem, MenuOverlay } from '@fs/zion-ui'
import { useFeatureFlag } from '@fs/zion-flags'
import {
  ControlView,
  EventImmigration,
  HelpCompass,
  MenuSettings,
  MediaPlay,
  SocialChat,
  ThingCalendar,
} from '@fs/zion-icon'
import { buildChatRoute, CHAT_ROUTE_KEY, useChatOverlay } from '@fs/zion-chat'
import { i18n } from '@fs/zion-locale'
import SignInButton from './SignInButton'
import { useAnonSessionPermission } from '../../hooks'
import { useChatContext, useLoggedOutOverlay } from '../../providers'
import { authConfig } from '../../config'
import { useDashboardPermissionsContext } from '../dashboard/providers/DashboardPermissionsProvider'

const chatRoute = buildChatRoute(CHAT_ROUTE_KEY.CHATS)
const ChatListItem = ({ user, onClick, dense }) => {
  const [t] = useTranslation()
  const linkName = 'Menu: Chat'
  const { overlay: loginOverlay } = useLoggedOutOverlay({
    showChat: chatRoute,
    tracking: linkName,
  })
  const chatOverlay = useChatOverlay(chatRoute)
  const needsToLogin = !user.signedIn && !user.userLoading
  const handleClick = (e) => {
    if (needsToLogin) {
      loginOverlay?.handleClick(e)
    } else {
      chatOverlay?.handleClick(e)
    }
    onClick()
  }
  return (
    <ListItem
      startElement={<ListItem.Icon Icon={SocialChat} />}
      onClick={handleClick}
      primaryText={t('navigation.chat', 'Chat')}
      linkName={linkName}
      dense={dense}
    />
  )
}

export const AvatarMenuItems = ({ dense, onClick }) => {
  const hasPersonalDataPermission = useAnonSessionPermission()
  const user = useUser()
  const { isChatApproved } = useChatContext()
  const [t] = useTranslation()
  const dashboardFlag = useFeatureFlag('rootstech_dashboard')
  const { permissions } = useDashboardPermissionsContext()
  const scheduleFlag = useFeatureFlag('rootstech_schedule')
  return (
    <>
      {scheduleFlag.isOn && user.signedIn && (
        <ListItem
          startElement={<ListItem.Icon Icon={ThingCalendar} />}
          data-testid="menu-my-schedule"
          linkName="Menu: My Schedule"
          onClick={onClick}
          primaryText={t('navigation.my-schedule.label', 'My Schedule')}
          to="/schedule?tab=my_schedule"
          dense={dense}
        />
      )}
      <ListItem
        startElement={<ListItem.Icon Icon={MediaPlay} />}
        data-testid="menu-my-playlists"
        linkName="Menu: My Playlist"
        onClick={onClick}
        primaryText={t('navigation.playlist.label', 'My Playlists')}
        to="/playlists/"
        dense={dense}
      />
      {isChatApproved && <ChatListItem user={user} onClick={onClick} dense={dense} />}
      {hasPersonalDataPermission && user.signedIn && (
        <ListItem
          startElement={<ListItem.Icon Icon={MenuSettings} />}
          linkName="Menu: User Preferences"
          onClick={onClick}
          data-testid="menu-preferences"
          primaryText={t('navigation.preferences.label', 'Preferences')}
          to="/user"
          dense={dense}
        />
      )}
      {hasPersonalDataPermission && user.signedIn && (
        <ListItem
          startElement={<ListItem.Icon Icon={ControlView} />}
          linkName="Menu: Watch History"
          onClick={onClick}
          data-testid="menu-watch-history"
          primaryText={t('navigation.watch-history.label', 'Watch History')}
          to="/history"
          dense={dense}
        />
      )}
      {dashboardFlag.isOn && permissions.hasDashboardAccess && hasPersonalDataPermission && user.signedIn && (
        <ListItem
          startElement={<ListItem.Icon Icon={HelpCompass} />}
          linkName="Menu: Dashboard"
          onClick={onClick}
          data-testid="menu-dashboard"
          primaryText={t('navigation.dashboard.label', 'Dashboard')}
          to="/dashboard/"
          dense={dense}
        />
      )}
      {user.signedIn && (
        <ListItem
          startElement={<ListItem.Icon Icon={EventImmigration} />}
          data-testid="menu-sign-out"
          linkName="Menu: Sign Out"
          primaryText={t('navigation.sign-out', 'Sign Out')}
          to={`${authConfig.logoutPath}?returnUrl=${encodeURIComponent(`/${i18n.language}/rootstech/`)}`}
          external
          dense={dense}
        />
      )}
      {!user.signedIn && (
        <ListItem
          data-testid="menu-sign-in"
          primaryText={<SignInButton onClick={onClick} linkName="Menu: Sign In" />}
          dense={dense}
        />
      )}
    </>
  )
}

export const AvatarMenuOverlay = ({ dense, ...overlay }) => {
  return (
    <MenuOverlay {...overlay}>
      <AvatarMenuItems dense={dense} onClick={overlay.close} />
    </MenuOverlay>
  )
}
