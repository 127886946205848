import React from 'react'
import { Card, H3, Image, MarginBox, Separator } from '@fs/zion-ui'
import ErrorBoundary from '@fs/zion-error-boundary'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import RainbowDivider from '../../../RainbowDivider'
import SafeRichText from '../../../SafeRichText'
import { PillButton } from '../../../buttons'
import { getCustomImage } from '../../../helpers'
import { flavors } from '../../../../config'
import ErrorFallback from '../../../ErrorFallback'

const flavoredCss = css`
  mask-image: var(--image);
  mask-repeat: no-repeat;
  mask-size: contain;
`

const FlavoredSVG = ({ src, flavor }) => {
  const fileType = src.split('.').pop()
  if (fileType !== 'svg') {
    return <Image src={src} alt="" />
  }

  return <div css={flavoredCss} style={{ '--image': `url(${src})`, background: flavors[flavor].gradient }} />
}

export default function IconCard({ data, flavor }) {
  const { title, description, ctaText, image, url } = data
  const [t] = useTranslation()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Card>
        <MarginBox marginX="sm" marginY="sm">
          <div style={{ height: 85, width: 85 }}>
            <FlavoredSVG src={getCustomImage(image)} flavor={flavor} />
          </div>
          <Separator size="nano" />
          <H3 supplementary>{title}</H3>
          <Separator size="xxs" />
          <RainbowDivider full flavor={flavor} />
          <Separator size="xxs" />
          <SafeRichText size="sm" dirtyInput={description} />
          {(ctaText || url) && (
            <>
              <Separator size="xs" />
              <PillButton to={url} linkName={`IconCard: ${title}: Link`} emphasis="medium">
                {ctaText || t('common-ui:learn-more.action', 'Learn More')}
              </PillButton>
            </>
          )}
        </MarginBox>
      </Card>
    </ErrorBoundary>
  )
}
