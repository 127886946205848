import { Separator, H2, Subheading, Row } from '@fs/zion-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SocialConnect, getConnectPlatformLinks } from '@fs/zion-social'
import { i18n } from '@fs/zion-locale'
import { useLocation } from '@fs/zion-router'
import { PillButton } from '../buttons'
import RainbowText from '../RainbowText'
import ResponsiveLayoutBand from '../ResponsiveLayoutBand'

const ConferenceUpdatesSection = ({ heading, iconButtonEmphasis = 'low' }) => {
  const [t] = useTranslation()
  const { pathname } = useLocation()

  const enPlatforms = {
    facebook: 'https://www.facebook.com/RootsTech',
    twitter: 'https://x.com/RootsTechConf',
    instagram: 'https://www.instagram.com/rootstechofficial/',
    youtube: 'https://www.youtube.com/@familysearch',
  }
  const platforms = i18n.language === 'en' ? enPlatforms : getConnectPlatformLinks(i18n.language)
  const flavor = 'fire'
  return (
    <ResponsiveLayoutBand>
      <H2 centered>
        <RainbowText flavor={flavor}>
          {heading || t('conference-announce.subscribe.for.updates', 'Subscribe for Updates')}
        </RainbowText>
        <Separator size="nano" />
        <Subheading>
          {t(
            'after-conference.subscribe.social-channels',
            'Check our social channels for the latest news and information about RootsTech.'
          )}
        </Subheading>
      </H2>
      <Separator />
      <Row alignX="center">
        <Separator size="md" />
        <PillButton to="/subscription" linkName="Subscribe for Updates" emphasis="medium">
          {t('conference-announce.subscribe.for.updates', 'Subscribe for Updates')}
        </PillButton>
      </Row>
      <Separator size="md" />
      <Row alignX="center">
        <SocialConnect
          platforms={platforms}
          useIconButtons
          iconButtonEmphasis={iconButtonEmphasis}
          analytics={{
            link_name: `connect: subscribe for updates`,
            link_page: `${pathname}`,
            link_app: 'rootstech',
          }}
        />
      </Row>
    </ResponsiveLayoutBand>
  )
}

export default ConferenceUpdatesSection
