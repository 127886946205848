import React, { useState, Children } from 'react'
import { ExpandableListItem, ListItem, MenuOverlay } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { useEvent } from '@fs/zion-frontend-friends'
import { ThingPresentation } from '@fs/zion-icon'
import { useNavBarItems } from '../../hooks'
import { filterNavItems } from '../../lib/helpers/menuHelper'

const WatchVideosItems = ({ dense, onClick, removeFiller = false }) => {
  const navItems = useNavBarItems()
  const [t] = useTranslation()

  const items = [
    {
      'data-testid': 'menu-watchVideo-library',
      linkName: 'OnDemand: Browse',
      primaryText: t('navigation.on-demand.library', 'Browse the Library'),
      onClick,
      to: '/library',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
    {
      'data-testid': 'menu-watchVideo-search',
      linkName: 'OnDemand: Search',
      primaryText: t('navigation.on-demand.search', 'Search for a Video'),
      onClick,
      to: '/search',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
    {
      'data-testid': 'menu-watchVideos-my-playlist',
      linkName: 'OnDemand: My Playlist',
      primaryText: t('navigation.playlist.label', 'My Playlists'),
      onClick,
      to: '/playlists/',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
    {
      linkName: 'OnDemand: Watch History',
      onClick,
      'data-testid': 'menu-watchVideos-watch-history',
      primaryText: t('navigation.watch-history.label', 'Watch History'),
      to: '/history',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
  ]

  const filteredItems = filterNavItems(items, navItems)
  return Children.toArray(filteredItems.map((item) => <ListItem {...item} />))
}

export const WatchVideosExpandableList = ({ dense, onClick }) => {
  const [t] = useTranslation()
  const [open, setOpen] = useState(false)
  const handleClick = useEvent(() => {
    setOpen(false)
    onClick()
  })

  return (
    <ExpandableListItem
      primaryText={t('navigation.watch-videos.label', 'Watch Videos')}
      startElement={<ListItem.Icon Icon={ThingPresentation} />}
      data-testid="menu-watchVideo"
      dense={dense}
      open={open}
      setOpen={setOpen}
      linkName={`On Demand: ${open ? 'open' : 'close'}`}
      selected={open}
    >
      <WatchVideosItems dense={dense} onClick={handleClick} />
    </ExpandableListItem>
  )
}

export const WatchVideosMenuOverlay = ({ dense, removeFiller, ...overlay }) => {
  return (
    <MenuOverlay {...overlay}>
      <WatchVideosItems onClick={overlay.close} dense={dense} removeFiller={removeFiller} />
    </MenuOverlay>
  )
}
