import React, { createContext, useCallback, useContext, useEffect, useReducer, useState, useMemo } from 'react'
import { useUser } from '@fs/zion-user'
import usePlaylistsApi from '../hooks/usePlaylistsApi'
import { deepCopy } from '../lib/helpers/functionalHelpers'
import notifySentry from '../lib/helpers/sentryNotifier'

const NO_PLAYLIST_SELECTED = Symbol('nothing-selected')

const events = {
  PLAYLISTS_LOADED: Symbol('playlists-loaded'),
  PLAYLIST_SELECTED: Symbol('playlist-selected'),
  NEW_PLAYLIST_CREATED: Symbol('new-playlist'),
  PLAYLIST_REMOVED: Symbol('playlist-removed'),
  METADATA_UPDATED: Symbol('metadata-updated'),
  ITEMS_LOADED: Symbol('items-loaded'),
  ITEMS_INVALIDATED: Symbol('items-invalidated'),
  USER_LOGGED_OUT: Symbol('user-logged-out'),
  ERROR: Symbol('error'),
  START_LOADING: Symbol('start-loading'),
}

const initialState = {
  playlists: null,
  selectedPlaylist: null,
  selectedPlaylistId: null,
  defaultPlaylistId: null,
  error: null,
  loading: null,
}

const alphabeticalOrder = (a, b) => {
  if (a?.default) {
    return -1
  }
  if (b?.default) {
    return 1
  }

  const aName = a?.name?.toLocaleLowerCase() ?? ''
  const bName = b?.name?.toLocaleLowerCase() ?? ''
  return aName.localeCompare(bName)
}

function reducer(state, { type, data } = {}) {
  const update = deepCopy(state)
  let unsortedPlaylists = null
  let selectedPlaylistId = null
  let updatedPlaylist = null
  update.error = null

  switch (type) {
    case events.PLAYLISTS_LOADED: {
      const { playlists: batch } = data
      unsortedPlaylists = [...(state.playlists?.values() ?? []), ...batch]

      const defaultPlaylist = batch.find((p) => p.default)
      if (defaultPlaylist) {
        update.defaultPlaylistId = defaultPlaylist.id
      }
      break
    }

    case events.PLAYLIST_SELECTED: {
      const { playlistId } = data
      if (playlistId === state.selectedPlaylistId) {
        return state
      }
      selectedPlaylistId = playlistId ?? NO_PLAYLIST_SELECTED
      break
    }

    case events.NEW_PLAYLIST_CREATED: {
      const { playlist } = data

      unsortedPlaylists = [...(state.playlists?.values() ?? []), playlist]
      break
    }

    case events.PLAYLIST_REMOVED: {
      const { playlistId } = data

      if (update.playlists.delete(playlistId)) {
        // make a copy so react updates things accordingly
        update.playlists = new Map(update.playlists.entries())
      }
      selectedPlaylistId = state.selectedPlaylistId === playlistId ? state.defaultPlaylistId : null
      break
    }

    case events.METADATA_UPDATED: {
      const { playlistId, metadata } = data

      const targetPlaylist = state.playlists.get(playlistId)
      if (!targetPlaylist) {
        break
      }

      updatedPlaylist = {
        ...targetPlaylist,
        ...metadata,
      }
      break
    }

    case events.ITEMS_LOADED: {
      const { playlistId, items } = data

      const targetPlaylist = state.playlists.get(playlistId)
      if (!targetPlaylist) {
        break
      }

      updatedPlaylist = {
        ...targetPlaylist,
        items,
        // handle when adding/removing and the items got invalidated
        size: items.length,
      }
      break
    }

    case events.ITEMS_INVALIDATED: {
      const { playlistId } = data
      const targetPlaylist = state.playlists.get(playlistId)
      if (!targetPlaylist) {
        break
      }

      updatedPlaylist = {
        ...targetPlaylist,
        items: undefined,
        nextPageToken: undefined,
      }
      break
    }

    case events.ERROR: {
      notifySentry(data)
      update.error = data
      update.loading = false
      break
    }

    case events.START_LOADING: {
      update.loading = true
      break
    }

    default: {
      return state
    }
  }

  if (selectedPlaylistId) {
    update.selectedPlaylistId = selectedPlaylistId === NO_PLAYLIST_SELECTED ? null : selectedPlaylistId
    update.selectedPlaylist = update.playlists.get(selectedPlaylistId)
  }

  if (unsortedPlaylists) {
    update.playlists = new Map(unsortedPlaylists.sort(alphabeticalOrder).map((p) => [p.id, p]))
  }

  if (updatedPlaylist) {
    // have to do a new map or react doesn't recognize it changed
    update.playlists = new Map(update.playlists.set(updatedPlaylist.id, updatedPlaylist).entries())
    if (update.selectedPlaylistId === updatedPlaylist.id) {
      update.selectedPlaylist = updatedPlaylist
    }
    update.loading = false
  }

  return update
}

const PlaylistsContext = createContext()
export const usePlaylistsContext = () => useContext(PlaylistsContext)

export function PlaylistsProvider({ children }) {
  const user = useUser()
  const api = usePlaylistsApi()

  const [{ playlists, selectedPlaylist, selectedPlaylistId, defaultPlaylistId, error, loading }, dispatch] = useReducer(
    reducer,
    initialState
  )

  const [initialized, setInitialized] = useState(false)
  useEffect(() => {
    async function doLoad() {
      if (user.signedIn) {
        if (initialized) {
          return
        }
        setInitialized(true)
        let offsetToken = null
        try {
          do {
            // eslint-disable-next-line no-await-in-loop
            const page = (await api.getPlaylistsApi(offsetToken)).data
            offsetToken = page.nextPageToken
            dispatch({ type: events.PLAYLISTS_LOADED, data: { playlists: page.results } })
          } while (offsetToken)
        } catch (e) {
          dispatch({ type: events.ERROR, data: e })
        }
      } else {
        dispatch({ type: events.USER_LOGGED_OUT })
        setInitialized(false)
      }
    }
    doLoad()
  }, [api, initialized, user.signedIn])

  const createPlaylist = async (playlist) => {
    const { data: result } = await api.createPlaylistApi({ data: playlist })
    dispatch({ type: events.NEW_PLAYLIST_CREATED, data: { playlist: result } })
    return result
  }

  const deletePlaylist = async (playlistId) => {
    await api.deletePlaylistApi({ playlistId })
    dispatch({ type: events.PLAYLIST_REMOVED, data: { playlistId } })
  }

  const loadItems = useCallback(
    async ({ playlistId }) => {
      dispatch({ type: events.START_LOADING })
      const playlist = playlists.get(playlistId)
      if (!playlist) {
        return
      }

      try {
        const { data } = await api.getPlaylistItemsApi({
          playlistId,
          nextPageToken: playlist.nextPageToken,
        })
        dispatch({ type: events.ITEMS_LOADED, data: { playlistId, items: data } })
      } catch (e) {
        dispatch({ type: events.ERROR, data: e })
      }
    },
    [api, playlists]
  )

  const addToPlaylist = async ({ playlistId, items }) => {
    if (items && !Array.isArray(items)) {
      // todo: log something? sentry event maybe?
      return
    }
    if (items.length === 0) {
      return
    }

    if (items.length > 1) {
      await api.bulkAddToPlaylistApi({ playlistId, sessionIds: items })
    } else {
      await api.addToPlaylistApi({ playlistId, sessionId: items[0] })
    }

    const playlist = playlists.get(playlistId)
    const isCurrentlyDisplayed = selectedPlaylistId === playlistId
    if (!isCurrentlyDisplayed && playlist.items) {
      dispatch({ type: events.ITEMS_INVALIDATED, data: { playlistId } })
    }

    if (isCurrentlyDisplayed) {
      await loadItems({ playlistId })
    } else {
      dispatch({
        type: events.METADATA_UPDATED,
        data: { playlistId, metadata: { size: playlist.size + items.length } },
      })
    }
  }

  const removeFromPlaylist = async ({ playlistId, itemId }) => {
    const playlist = playlists.get(playlistId)
    if (!playlist) {
      return
    }

    await api.removeFromPlaylistApi({ playlistId, sessionId: itemId })

    if (selectedPlaylistId === playlistId || playlist.items) {
      dispatch({
        type: events.ITEMS_LOADED,
        data: { playlistId, items: playlist.items.filter((i) => i.id !== itemId) },
      })
    } else {
      dispatch({
        type: events.METADATA_UPDATED,
        data: { playlistId, metadata: { size: playlist.size - 1 } },
      })
    }
  }

  const selectPlaylist = (playlistId) => {
    dispatch({ type: events.PLAYLIST_SELECTED, data: { playlistId } })
  }

  const updatePlaylistMetadata = async ({ playlistId, name, description }) => {
    await api.updatePlaylistApi({ playlistId, data: { name, description } })
    dispatch({ type: events.METADATA_UPDATED, data: { playlistId, name, description } })
  }

  const updateItemOrder = async ({ playlistId, update: { ids, ascending } }) => {
    let ordering = null
    if (ids) {
      ordering = { method: 'custom', ids }
    } else if (ascending !== undefined) {
      ordering = { method: 'insertion', ascending }
    } else {
      return
    }

    await api.reorderPlaylistApi({ playlistId, ordering })
    dispatch({ type: events.METADATA_UPDATED, data: { playlistId, metadata: { ordering } } })
  }

  const findPlaylistsWithItem = useCallback(
    async ({ itemId }) =>
      (await api.getPlaylistsForItemApi({ sessionId: itemId, signedIn: user.signedIn })).data.results,
    [api, user.signedIn]
  )

  const followPlaylist = async (playlistId) => {
    let playlist = null
    try {
      const { data: result } = await api.followPlaylistApi({ seriesId: playlistId })
      playlist = result
    } catch (err) {
      if (err.statusCode === 409) {
        playlist = err.response.data
      }
      throw err
    }
    dispatch({ type: events.NEW_PLAYLIST_CREATED, data: { playlist } })
    return playlist
  }

  const isFollowing = (playlistId) => {
    return [...playlists.values()].some((p) => p.parent?.id === playlistId)
  }

  const series = useMemo(() => (playlists ? [...playlists.values()].filter((p) => p.parent) : []), [playlists])

  return (
    <PlaylistsContext.Provider
      // FIXME: This is a temporary fix. We need to remove this eslint-disable line and fix the issue.
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        playlists,
        selectedPlaylist,
        selectedPlaylistId,
        defaultPlaylistId,
        createPlaylist,
        deletePlaylist,
        loadItems,
        addToPlaylist,
        removeFromPlaylist,
        selectPlaylist,
        updatePlaylistMetadata,
        updateItemOrder,
        findPlaylistsWithItem,
        followPlaylist,
        isFollowing,
        error,
        series,
        loading,
      }}
    >
      {children}
    </PlaylistsContext.Provider>
  )
}
