import React, { Children } from 'react'
import {
  FlowGrid,
  LayoutBand,
  Row,
  Separator,
  WhiteTheme,
  Image,
  Ellipsis,
  Card,
  // MarginBox,
  useContainerWidth,
  // Bleed,
  H3,
  H5,
  Overline,
  Subheading,
} from '@fs/zion-ui'
import ErrorBoundary from '@fs/zion-error-boundary'
// import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Menu } from '@fs/zion-icon'
import { Link, useRouteMatch } from '@fs/zion-router'
import { HorizontalCardGrid } from '../../../components/home/components'
// import { flavors, scrimGradient } from '../../../config'
import { PillButton } from '../../buttons'
// import RainbowDivider from '../../RainbowDivider'
import SafeRichText from '../../SafeRichText'
import {
  getCustomImage,
  getRelativePath,
  isAttachmentConferencePromo,
  isCalendarModule,
  isClientContent,
  isConferencePromo,
  isCountdownModule,
  isCreatorConferencePromo,
  isExhibitorConferencePromo,
  isExternalConferencePromo,
  isListModule,
  isPageConferencePromo,
  isRichTextModule,
  isSeriesConferencePromo,
  isSessionConferencePromo,
  isVideoConferencePromo,
  isVideoModule,
  isLanguageBannerModule,
  isMainStageModule,
} from '../../helpers'
import Timestamp from '../../Timestamp'
import { StrongLink } from '../../link'
import { LanguageBadge, SeriesBadge } from '../../Badge'
import { MainStagePlayer, ScheduledPlayer, VideoProvider } from '../../video'
import PromoBanner from '../../banners/PromoBanner'
import BorderPromoBanner from '../../banners/BorderPromoBanner'
import ResponsiveSeparator from '../../ResponsiveSeparator'
import { CountDownBanner } from '../../CountDown'
import BackgroundImagePromo from '../../BackgroundImagePromo'
import { seafoamRibbonLargeJpg } from '../../../images'
import ResponsiveSectionBand from '../../ResponsiveSectionBand'
import ErrorFallback from '../../ErrorFallback'

// const flavorCss = css`
//   border-radius: 8px;
//   background: var(--scrim), var(--gradient);
// `

const Creators = ({ creators }) => {
  return creators?.map((creator) => {
    return (
      <StrongLink rel="author" to={getRelativePath(creator?.url)} linkName={`Creator: ${creator?.name}`}>
        {creator?.name}
      </StrongLink>
    )
  })
}

// const PagePromo = ({ promo, flavor, noScrim = true }) => {
//   const atWidth = useContainerWidth()
//   const marginSize = atWidth({ default: 'nano', xxs: 'xxs', xs: 'xs', sm: 'sm', md: 'md' })
//   return (
//     <Card edges="border">
//       <Bleed all>
//         <div
//           style={{
//             height: 300,
//             background: `url(${getCustomImage(promo)}) no-repeat top center/cover`,
//             borderRadius: 8,
//             '--scrim': noScrim ? flavors.flavorless.filmedGradient : scrimGradient,
//             '--gradient': flavors[flavor].gradient,
//           }}
//           css={flavorCss}
//           flavor={flavor}
//         >
//           <MarginBox marginX={marginSize} marginY={marginSize}>
//             <Row alignY="middle">
//               <H3 size="H2" noShrinkX>
//                 <Overline>{promo.overlineText}</Overline>
//                 {promo.title}
//               </H3>
//               <div growX />
//               <PillButton to={getRelativePath(promo.url)} noShrinkX>
//                 {promo.ctaText || 'Check it out now!'}
//               </PillButton>
//             </Row>
//             <Separator size="nano" />
//             <RainbowDivider thick and full of flavor={flavor} />
//             <Separator size="nano" />
//             {promo.description && <SafeRichText dirtyInput={promo.description} />}
//             <Separator />
//           </MarginBox>
//         </div>
//       </Bleed>
//     </Card>
//   )
// }

const SessionSeriesPromo = ({ promo }) => {
  const isSeries = isSeriesConferencePromo(promo)
  const to = getRelativePath(promo.url)
  const hasCreators = promo.creators?.length > 0
  return (
    <Card>
      <Row>
        <Link to={to} noShrinkX>
          <Image
            src={getCustomImage(promo)}
            style={{ aspectRatio: '16/9', borderRadius: '8px' }}
            maxHeight={200}
            loading="lazy"
          />
        </Link>
        <div growX>
          <Row marginY="none">
            <LanguageBadge contentLocale={promo.contentLocale} />
            {isSeries ? <SeriesBadge /> : null}
            <Timestamp conferenceYear={promo.conferenceYear} />
          </Row>
          <Separator size="nano" />
          <H5>
            <Overline>{promo.overlineText}</Overline>
            <WhiteTheme>
              <StrongLink to={to} linkName={promo.title}>
                {promo.title}
              </StrongLink>
            </WhiteTheme>
            <Subheading>{hasCreators ? <Creators creators={promo.creators} /> : undefined}</Subheading>
          </H5>
          {promo.description && (
            <Ellipsis lineClamp={hasCreators ? 3 : 4} noTooltip>
              <SafeRichText dirtyInput={promo.description} />
            </Ellipsis>
          )}
        </div>
        <PillButton Icon={Menu} noShrinkX>
          Actions
        </PillButton>
      </Row>
    </Card>
  )
}

const VideoConferencePromo = ({ promo }) => {
  const atWidth = useContainerWidth()
  return (
    <FlowGrid columnCount={atWidth({ default: 1, lg: 2 })}>
      <div>
        <H5 centered={atWidth({ default: true, lg: false })}>
          <Overline>{promo.overline}</Overline>
          {promo.title}
        </H5>
        {promo.description && (
          <Row alignX={atWidth({ default: 'center', lg: 'start' })}>
            <SafeRichText dirtyInput={promo.description} />
          </Row>
        )}
      </div>
      <div style={{ aspectRatio: '16/9' }}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <VideoProvider
            videoProvider={promo.video?.videoProvider}
            sessionId={promo.contentId}
            contentLocale={promo.video?.contentLocale}
          />
        </ErrorBoundary>
      </div>
    </FlowGrid>
  )
}

export function ConferencePromoContent({ promo, flavor }) {
  const [t] = useTranslation()
  // Only update the banners for registration overview page, in-person page and online page
  const isRegistrationPage = Boolean(useRouteMatch('/registration*'))

  const Banner = isRegistrationPage ? BorderPromoBanner : PromoBanner

  return (
    <ResponsiveSectionBand>
      {isVideoConferencePromo(promo) && <VideoConferencePromo promo={promo} />}
      {(isSessionConferencePromo(promo) || isSeriesConferencePromo(promo)) && (
        <SessionSeriesPromo promo={promo} flavor={flavor} />
      )}
      {(isExhibitorConferencePromo(promo) ||
        isPageConferencePromo(promo) ||
        isExternalConferencePromo(promo) ||
        isAttachmentConferencePromo(promo) ||
        isCreatorConferencePromo(promo)) && (
        <Banner
          overline={promo?.overlineText}
          heading={promo?.title}
          description={promo?.description}
          flavor={flavor}
          buttonText={promo?.url && promo?.ctaText ? promo?.ctaText : t('common-ui:learn-more.action', 'Learn More')}
          image={promo?.image?.custom?.image?.src}
          to={getRelativePath(promo?.url)}
        />
      )}
    </ResponsiveSectionBand>
  )
}

const CalendarModuleContent = ({ section }) => {
  return (
    <ResponsiveSectionBand>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ScheduledPlayer heading={section.calendar.title} sessions={section.calendar.stages?.[0]?.calendarItems} />
      </ErrorBoundary>
    </ResponsiveSectionBand>
  )
}

export const RichTextModuleContent = ({ section }) => {
  return (
    <ResponsiveSectionBand>
      <SafeRichText dirtyInput={section.body} />
    </ResponsiveSectionBand>
  )
}

export const ListModuleContent = ({ section }) => {
  return (
    <ResponsiveSectionBand maxWidth="xl">
      <HorizontalCardGrid items={section?.items} heading={section.title} as="h2" />
    </ResponsiveSectionBand>
  )
}

const VideoModuleContent = ({ section }) => {
  return (
    <ResponsiveSectionBand>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <VideoProvider
          thumbnail={getCustomImage(section)}
          videoProvider={section.video.videoProvider}
          sessionId={section.id}
          contentLocale={section.video.contentLocale}
        />
      </ErrorBoundary>
      <Separator size="nano" />
      <H3>
        {section.title}
        <Subheading>{section.description}</Subheading>
      </H3>
    </ResponsiveSectionBand>
  )
}

const ClientContent = ({ section }) => {
  return <ResponsiveSectionBand>{section.Component}</ResponsiveSectionBand>
}

/**
 * Why not make this from BSP? Because translations are easier in the code, and the design may change in the future. Soon™
 */
const LanguageBannerContent = () => {
  const [t] = useTranslation()
  const promo = {
    imgSrc: seafoamRibbonLargeJpg,
    title: t('language-banner.heading', 'Do you speak a second language?'),
    description: t(
      'language-banner.description',
      'RootsTech has thousands of classes across dozens of languages. You can change your site language, or use the Search page to find them.'
    ),
    ctaText: t('go-to-search.button', 'Go to Search'),
    url: '/search',
  }

  return (
    <LayoutBand>
      <BackgroundImagePromo promo={promo} />
    </LayoutBand>
  )
}

export default function PageContent({ content, flavor }) {
  return Children.toArray(
    content?.map((section) => {
      return (
        <>
          {isCountdownModule(section) && <CountDownBanner />}
          <ResponsiveSeparator />
          {isCalendarModule(section) && <CalendarModuleContent section={section} />}
          {isRichTextModule(section) && <RichTextModuleContent section={section} />}
          {isListModule(section) && <ListModuleContent section={section} />}
          {isConferencePromo(section) && <ConferencePromoContent promo={section} flavor={flavor} />}
          {isVideoModule(section) && <VideoModuleContent section={section} />}
          {isClientContent(section) && <ClientContent section={section} />}
          {isLanguageBannerModule(section) && <LanguageBannerContent />}
          {isMainStageModule(section) && <MainStagePlayer />}
        </>
      )
    })
  )
}
