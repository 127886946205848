import React, { lazy, Suspense } from 'react'
import { ListItem, MenuOverlay, DialogOverlay, useOverlay, List, Separator } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from '@fs/zion-flags'
import { i18n } from '@fs/zion-locale'
import { PlaceHome, PlaceStore } from '@fs/zion-icon'
import PropTypes from 'prop-types'
import { RainbowDivider } from '../../lib'
import { useAnonSessionPermission, useIsMdWidth } from '../../hooks'
import MenuButton from './MenuButton'
import { AvatarMenuItems } from './AvatarMenuOverlay'
import { MiniEventsExpandableList } from './MiniEventsMenu'
import { RootstechEventExpandableList } from './RootstechEventMenu'
import { WatchVideosExpandableList } from './WatchVideosMenu'
import { storeConfig } from '../../config'
import { LanguageExpandableList } from './LanguageButton'
import HelpExpandableList from './HelpButton/HelpExpandableList'

const LangPicker = lazy(() => import('@fs/zion-lang-picker'))

const HomeListItem = ({ dense = false, onClick }) => {
  const [t] = useTranslation()
  return (
    <ListItem
      data-testid="menu-home"
      to="/"
      primaryText={t('navigation.home.label', 'Home')}
      startElement={<ListItem.Icon Icon={PlaceHome} />}
      onClick={onClick}
      linkName="Menu: Home"
      dense={dense}
    />
  )
}
HomeListItem.propTypes = {
  dense: PropTypes.bool,
  onClick: PropTypes.func,
}

// onClick is used by MobileMenu to make sure the menu is closed when an item is selected
const MenuItems = ({ showNavItems, onClick, onLangClick, dense, removeFiller, flavor = 'blueberry' }) => {
  const userLocale = i18n.language
  const [t] = useTranslation()
  const storeFlag = useFeatureFlag('rootstech_store')

  return (
    <>
      {showNavItems && (
        <>
          <RootstechEventExpandableList onClick={onClick} dense={dense} removeFiller={removeFiller} />
          {userLocale === 'en' && (
            <MiniEventsExpandableList onClick={onClick} dense={dense} removeFiller={removeFiller} />
          )}
          <WatchVideosExpandableList onClick={onClick} dense={dense} removeFiller={removeFiller} />
          <LanguageExpandableList onClick={onClick} onLangClick={onLangClick} dense={dense} />
          <HelpExpandableList onClick={onClick} onLangClick={onLangClick} dense={dense} />
          <Separator size="nano" />
          <RainbowDivider full of flavor={flavor} />
          <Separator size="nano" />
          {storeFlag.isOn && (
            <ListItem
              startElement={<ListItem.Icon Icon={PlaceStore} />}
              data-testid="menu-rootstech-store"
              linkName="Menu: RootsTech Store"
              onClick={onClick}
              primaryText={t('navigation.rootstech-store.label', 'RootsTech Store')}
              to={storeConfig.to}
              external
              target="_blank"
              dense={dense}
            />
          )}
          <AvatarMenuItems onClick={onClick} dense={dense} />
        </>
      )}
    </>
  )
}
MenuItems.propTypes = {
  showNavItems: PropTypes.bool,
  onClick: PropTypes.func,
  onLangClick: PropTypes.func,
}

function MenuDialogOverlay({ showNavItems, onLangClick, hasPersonalDataPermission, dense, removeFiller = false }) {
  const [t] = useTranslation()
  const overlay = useOverlay()
  return (
    <>
      <MenuButton data-testid="mobile-menu-button" onClick={overlay.handleClick} active={overlay.isOpen} />
      {(overlay.isOpen || overlay.transitioning) && (
        <DialogOverlay {...overlay} fullscreen title={t('common-ui:menu.label', 'Menu')}>
          <List aria-label="Submenu">
            <MenuItems
              showNavItems={showNavItems}
              onClick={overlay.close}
              onLangClick={() => {
                overlay.close()
                onLangClick()
              }}
              hasPersonalDataPermission={hasPersonalDataPermission}
              dense={dense}
              removeFiller={removeFiller}
            />
          </List>
        </DialogOverlay>
      )}
    </>
  )
}
MenuDialogOverlay.propTypes = {
  showNavItems: PropTypes.bool,
  onLangClick: PropTypes.func,
  hasPersonalDataPermission: PropTypes.bool,
}

export default function Menu({ dense, showNavItems }) {
  const hasPersonalDataPermission = useAnonSessionPermission()
  const isMdWidth = useIsMdWidth()
  const langOverlay = useOverlay()
  const menuOverlay = useOverlay()

  return (
    <>
      {isMdWidth ? (
        <MenuDialogOverlay
          showNavItems={showNavItems}
          onLangClick={langOverlay.handleClick}
          hasPersonalDataPermission={hasPersonalDataPermission}
          dense={dense}
        />
      ) : (
        <>
          <MenuButton data-testid="menu-button" onClick={menuOverlay.handleClick} active={menuOverlay.isOpen} />
          {(menuOverlay.isOpen || menuOverlay.transitioning) && (
            <MenuOverlay {...menuOverlay} inline={false} aria-label="Submenu">
              <MenuItems
                showNavItems={showNavItems}
                onLangClick={langOverlay.handleClick}
                onClick={menuOverlay.close}
                hasPersonalDataPermission={hasPersonalDataPermission}
                dense={dense}
                removeFiller
              />
            </MenuOverlay>
          )}
        </>
      )}
      <Suspense fallback={<span />}>
        {(langOverlay.isOpen || langOverlay.transitioning) && <LangPicker overlay={langOverlay} />}
      </Suspense>
    </>
  )
}
Menu.propTypes = {
  showNavItems: PropTypes.bool,
}
